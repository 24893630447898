























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'

@Component({
  components: {
    Modal,
    Button,
    DateTimePicker,
    CloseLine,
  },
})
export default class ModalNotes extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: '' }) private notes!: string
  @Prop({ default: 'Catatan' }) private title!: string

  get note(): string {
    return this.notes
  }
}
