export const typeOptions = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'Rekonsiliasi Penambahan Penghasilan',
    value: 'BAL_ADD_RCC',
  },
  {
    label: 'Rekonsiliasii Pengurangan Penghasilan',
    value: 'BAL_RDC_RCC',
  },
]

export const uploadTypeOptions = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'Satuan',
    value: 'SINGLE',
  },
  {
    label: 'Bulky',
    value: 'BULKY',
  },
]

export const activityOptions = [
  { label: 'Tambah Penghasilan', value: 'ADD_BAL' },
  { label: 'Tarik Penghasilan', value: 'RDC_BAL' },
]

export const perPageOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

export const datepickerPresets = [
  {
    key: 'last31days',
    label: '31 Hari Terakhir',
    value: 30,
  },
]

export enum enumStatus {
  WAITING = 'WAITING FOR APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum SortFields {
  CREATED_AT = 'TRANSACTION_CREATED',
  APPROVED_AT = 'TRANSACTION_APPROVED',
  ID = 'TRANSACTION_ID'
}

export enum SortType {
  CREATED_AT_ASC = 'TRANSACTION_CREATED-ASC',
  CREATED_AT_DESC = 'TRANSACTION_CREATED-DESC',
  APPROVED_AT_ASC = 'TRANSACTION_APPROVED-ASC',
  APPROVED_AT_DESC = 'TRANSACTION_APPROVED-DESC',
  ID_ASC = 'TRANSACTION_ID-ASC',
  ID_DESC = 'TRANSACTION_ID-DESC'
}

export enum EnumAlertMessage {
  WAITING_FOR_APPROVAL = "Permintaan adjust basic fee telah dikirim oleh [name] tanggal [date] pukul [time]",
  APPROVE = "Permintaan adjust basic fee telah diterima oleh [name] tanggal [date] pukul [time]",
  REJECT = "Permintaan adjust basic fee telah ditolak oleh [name] tanggal [date] pukul [time]"
}
