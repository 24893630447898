function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"adjust-basic-fee-approval"},[_c('h1',{staticClass:"text-gray-4 font-bold"},[_vm._v("Daftar Permintaan Adjust Basic Fee")]),_c('div',{staticClass:"flex flex-row gap-3"},[_c('div',{staticClass:"flex flex-col gap-2 w-2/5"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"type":"search","placeholder":"Cari nama file, email pengirim atau penerima"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.keyword),callback:function ($$v) {_vm.$set(_vm.parameters, "keyword", $$v)},expression:"parameters.keyword"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-1/5"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Tanggal")]),_c('DateTimePickerV2',{on:{"input":_vm.fetchList},model:{value:(_vm.parameters.date),callback:function ($$v) {_vm.$set(_vm.parameters, "date", $$v)},expression:"parameters.date"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-2/12"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Status")]),_c('DropdownSelect',{attrs:{"options":_vm.filterOptions},on:{"input":_vm.onFilterStatus},model:{value:(_vm.parameters.status),callback:function ($$v) {_vm.$set(_vm.parameters, "status", $$v)},expression:"parameters.status"}})],1)]),_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.dataTable,"sort":_vm.parameters.sort},on:{"sort":_vm.onSort},scopedSlots:_vm._u([{key:"0",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('span',{staticClass:"font-medium text-sm text-gray-4"},[_vm._v(_vm._s(data.data.value))])]}},{key:"1",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(data.data.value))])]}},{key:"2",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"text-sm text-secondaryText"},[(data.data.value.length)?[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])]:[_vm._v("-")]],2)]}},{key:"3",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(data.data.value))])]}},{key:"4",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"text-sm text-secondaryText"},[(data.data.value.length)?[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])]:[_vm._v("-")]],2)]}},{key:"5",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-sm text-secondaryText line-clamp-1"},[_vm._v(_vm._s(data.data.value))]),(data.data.value !== '-')?_c('span',{staticClass:"text-2xs text-bgPrimary cursor-pointer",on:{"click":function () { return _vm.handleModalNotes(true, data.data.value); }}},[_vm._v("Lihat Detail")]):_vm._e()])]}},{key:"6",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('Badge',{staticClass:"w-full px-4 py-1 text-center text-secondaryText hdpi-laptop:w-3/4",attrs:{"data-testid":"global-component-data-table-v1__row__badge","type":_vm.formatedStatus(data.data.value).color,"customPadding":true}},[_vm._v(" "+_vm._s(_vm.formatedStatus(data.data.value).text)+" ")])]}},{key:"7",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [(data.data.value.split(';')[1])?_c('router-link',{staticClass:"flex gap-2 cursor-pointer",attrs:{"to":{
          name: 'AdjustBasicFeeApprovalDetail',
          params: {
            historyId: data.data.value.split(';')[0],
          },
        }}},[_c('EyeIcon'),_c('span',{staticClass:"text-sm text-bgPrimary font-semibold"},[_vm._v("Lihat")])],1):_vm._e()]}}])}),_c('PaginationNav',{attrs:{"lang":"id","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.historyManualAdjustBasicFeePaginationData.totalItem,"page":_vm.parameters.page},on:{"input":function () {
        _vm.fetchList()
      },"onChangeOption":function (val) {
        _vm.parameters.perPage = val
        _vm.fetchList(true)
      }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('ModalNotes',{attrs:{"visible":_vm.modalNotes.visible,"notes":_vm.modalNotes.notes},on:{"close":function () { return _vm.handleModalNotes(false, ''); }}}),(_vm.controller.isLoadingHistoryManualAdjustBasicFee)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }