























import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconCalender from '@/app/ui/assets/icon_calendar.vue'
import IconClock from '@/app/ui/assets/icon_clock.vue'

@Component({
  components: {
    VueCtkDateTimePicker,
    IconCalender,
    IconClock,
  },
})
export default class DateTimePicker extends Vue {
  @Prop({ default: 'date' }) private valueFormat!: 'date' | 'time' | 'datetime'
  @Prop({ type: Boolean, default: false }) private withIcon!: boolean
  @Prop({ type: Boolean, default: false }) private error!: boolean

  get formattedValue(): string {
    switch (this.valueFormat) {
      case 'date':
        return 'YYYY-MM-DD'
      case 'time':
        return 'HH:mm:ss'
      default:
        return 'YYYY-MM-DD HH:mm:ss'
    }
  }

  isOpen(): void {
    const el: HTMLInputElement | null = this.$el.querySelector('input')
    if (el) {
      el.removeAttribute('style')
    }
    this.$emit('open')
  }
}
