


































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import {
  enumStatus,
  perPageOptions,
} from '@/app/infrastructures/misc/Constants/manualAdjustBasicFee'
import EyeIcon from '@/app/ui/assets/eye_filled.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import PayrollController from '@/app/ui/controllers/PayrollController'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import {
  HeaderObject,
  DataObject,
  SortValueObject,
} from '@/app/ui/components/DataTableV2/type'
import { ManualAdjustBasicFeeHistory } from '@/domain/entities/Payroll'
import { Utils } from '@/app/infrastructures/misc'
import ModalNotes from '../components/Modals/ModalNotes/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

@Component({
  components: {
    TextInput,
    DateTimePickerV2,
    DropdownSelect,
    DataTableV2,
    Badge,
    EyeIcon,
    PaginationNav,
    AscendingIcon,
    ModalNotes,
    LoadingOverlay,
  },
})
export default class AdjustBasicFeeApprovalList extends Vue {
  controller = PayrollController
  enumStatus = enumStatus
  perPageOptions = perPageOptions
  filterOptions = [
    { label: 'Semua', value: '' },
    { label: 'Terima', value: this.enumStatus.APPROVED },
    { label: 'Tolak', value: this.enumStatus.REJECTED },
    { label: 'Menunggu', value: this.enumStatus.WAITING },
  ]

  headers = [
    this.headerCellMapper(
      'Nama File',
      '220px',
      true,
      'HISTORY_NAME_FILE',
      '100px'
    ),
    this.headerCellMapper('Dikirim Oleh', '225px'),
    this.headerCellMapper(
      'Tanggal Dikirim',
      '200px',
      true,
      'HISTORY_CREATED',
      '20px'
    ),
    this.headerCellMapper('Diterima/Tolak Oleh', '225px'),
    this.headerCellMapper(
      'Tanggal Terima/Tolak',
      '225px',
      true,
      'HISTORY_APPROVAL',
      '10px'
    ),
    this.headerCellMapper('Catatan', '225px'),
    this.headerCellMapper('Status', '220px'),
    this.headerCellMapper('Aksi', '126px'),
  ]

  dataTable: DataObject[][] = []

  modalNotes = {
    visible: false,
    notes: '',
  }

  parameters = {
    page: 1,
    perPage: 10,
    keyword: '',
    date: <Date | null>null,
    status: this.filterOptions[0],
    sort: <SortValueObject>{
      direction: '',
      name: '',
    },
  }

  created(): void {
    this.fetchList(true)
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.keyword,
      columnSort: <string>this.parameters.sort.name,
      sort: <string>this.parameters.sort.direction,
      timeZone: encodeURIComponent(
        Utils.formatDateWithIDLocale(new Date().toISOString(), 'Z')
      ),
      date: this.parameters.date
        ? Utils.formatDateWithIDLocale(
            <string>this.parameters.date.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
      status: this.parameters.status.value
    }
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getAllHistoryManualAdjustBasicFee(this.params)
  }

  public onSort(sortObj: SortValueObject): void {
    this.parameters.sort = sortObj

    this.fetchList(true)
  }

  public handleModalNotes(visible: boolean, note: string): void {
    this.modalNotes.visible = visible
    this.modalNotes.notes = note
  }

  public onFilterStatus(status: { label: string; value: string } | null): void {
    if (!status) {
      this.parameters.status = this.filterOptions[0]
    }

    this.fetchList(true)
  }

  public onSearch = Utils.debounce(() => {
    this.fetchList(true)
  }, 500)

  public formatedStatus(
    status?: string
  ): {
    text: string
    color: string
  } {
    switch (status) {
      case this.enumStatus.APPROVED:
        return {
          text: 'Diterima',
          color: 'success',
        }
      case this.enumStatus.REJECTED:
        return {
          color: 'error-1',
          text: 'Ditolak',
        }
      default:
        return {
          color: 'warning',
          text: 'Menunggu',
        }
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    withSort?: boolean,
    sortName?: string,
    sortGap?: string
  ): HeaderObject {
    return {
      title: <string>title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
      withSort,
      sortName,
      sortStyle: {
        marginLeft: sortGap,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): DataObject {
    return {
      value: <string>value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.dataHistoryManualAdjustBasicFee')
  onDataHistoryManualAdjustBasicFeeChanged(
    data: ManualAdjustBasicFeeHistory[]
  ): void {
    this.dataTable = data.map(history => {
      return [
        this.tableCellMapper(history.filename || '-', '220px'),
        this.tableCellMapper(history.createdBy || '-', '225px'),
        this.tableCellMapper(
          history.createdAt
            ? [
                Utils.formatDateWithIDLocale(
                  <string>history.createdAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    <string>history.createdAt,
                    'HH:mm Z'
                  )
                ),
              ]
            : [],
          '200px'
        ),
        this.tableCellMapper(history.updatedBy || '-', '225px'),
        this.tableCellMapper(
          history.updatedAt
            ? [
                Utils.formatDateWithIDLocale(
                  <string>history.updatedAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    <string>history.updatedAt,
                    'HH:mm Z'
                  )
                ),
              ]
            : [],
          '214px'
        ),
        this.tableCellMapper(history.reason || '-', '225px'),
        this.tableCellMapper(<string>history.statusApproval, '220px'),
        this.tableCellMapper(
          history.filepath ? `${history.id};${history.filepath}` : '-',
          '126px'
        ),
      ]
    })
  }
}
