





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import ChevronLeftIcon from '@/app/ui/assets/chevron-left.vue'
import ChevronRightIcon from '@/app/ui/assets/chevron-right.vue'

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
})
export default class MonthPicker extends Vue {
  @Prop({ default: () => new Date() }) private value!: Date
  @Prop({ default: undefined }) private maxMonth!: Date | undefined
  @Prop({ default: undefined }) private minMonth!: Date | undefined

  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  selectMode: 'month' | 'year' = 'month'
  currentYear = new Date().getFullYear()
  isPickerOpen = false

  get yearLabel(): string {
    return this.selectMode === 'year'
      ? `${this.getYearList(this.currentYear)[0]} - ${
          this.getYearList(this.currentYear)[7]
        }`
      : `${this.currentYear}`
  }

  get dateValue(): Date {
    return new Date(this.value?.setDate(1))
  }

  public getYearList(year: number): number[] {
    return Array.from({ length: 12 }, (_x, i) => year - 8 + i)
  }

  public decideMonthClass(month: number, year: number): string | undefined {
    if (this.maxMonth) {
      if (this.maxMonth.getFullYear() < year) {
        return 'disabled'
      }

      if (
        this.maxMonth.getFullYear() === year &&
        new Date().getMonth() < month
      ) {
        return 'disabled'
      }
    }

    if (
      this.dateValue.getMonth() === month &&
      this.currentYear === this.dateValue.getFullYear()
    ) {
      return 'selected'
    }
  }

  public decideYearClass(year: number): string | undefined {
    if (this.maxMonth) {
      if (this.maxMonth.getFullYear() < year) {
        return 'disabled'
      }
    }

    if (this.dateValue.getFullYear() === year) {
      return 'selected'
    }
  }

  public onPrevYear(): void {
    if (this.selectMode === 'year') {
      this.currentYear = this.getYearList(this.currentYear)[0] - 4
    } else {
      this.currentYear--
    }
  }

  public onNextYear(): void {
    if (this.selectMode === 'year') {
      this.currentYear = this.getYearList(this.currentYear)[8] + 12
    } else {
      this.currentYear++
    }
  }

  public onChangeMode(): void {
    if (this.selectMode === 'month') {
      this.selectMode = 'year'
    } else {
      this.selectMode = 'month'
    }
  }

  public onSelectYear(year: number): void {
    this.currentYear = year
    this.selectMode = 'month'
  }

  public onSelectMonth(month: number, year: number): void {
    const tempDate = new Date(new Date().setHours(0, 0, 0, 0))
    tempDate.setDate(1)
    this.$emit('input', new Date(tempDate.setFullYear(year, month)))
    this.isPickerOpen = false
  }

  public onToggleMonthPicker(): void {
    this.isPickerOpen = !this.isPickerOpen
  }
}
