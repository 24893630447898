import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { PayrollPresenter } from '@/app/ui/presenters/PayrollPresenter'
import {
  CreatePayrollApiRequest,
  SubmitCourierAttendanceApprovalRequest,
  SubmitApprovalManualAdjustBasicFeeRequest,
  UpdatePeriodBasicFeeApiRequest,
  UpdatePeriodBonusFeeApiRequest,
  UpdatePeriodWithdrawApiRequest,
  UploadBulkyManualAdjustBaicFeeRequest,
} from '@/data/payload/api/PayrollRequest'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  Payrolls,
  Payroll,
  PeriodRule,
  CourierAttendance,
  CourierAttendences,
  CourierAttendanceDetails,
  CourierAttendanceRequestDetail,
  ManualAdjustBasicFee,
  ManualAdjustBasicFeeHistory,
  ManualAdjustBasicFees,
  ManualAdjustBasicFeeHistories,
  ManualAdjustBasicFeeHistoryDetailData,
  ManualAdjustBasicFeeHistoryDetail,
} from '@/domain/entities/Payroll'
import { Pagination } from '@/domain/entities/Pagination'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'

export interface PayrollPeriodPayload {
  startDate: string
}

export interface FundsWithdrawalPayload {
  min_payout_amount?: number
  min_saldo?: number
  payout_fee?: number
}

export interface BasicFeePayload {
  calculation_method: string[]
  multiply_type: string[]
  product_type: string[]
  rules: RulePayload[]
}

export interface RulePayload {
  calculation_method?: string
  delivery_tier?: SettingPayload[]
  pickup_tier?: SettingPayload[]
  product_type?: string
}

export interface BonusFeePayload {
  bonus_recipient?: string[]
  captain_bonus?: string[]
  captain_bonus_fee?: number
  captain_bonus_options?: string[]
  insurance?: boolean
  performance_bonus?: boolean
  performance_bonus_data?: BonusDataPayload[]
  percentage_bonus?: boolean
  percentage_bonus_data?: BonusPercentagePayload[]
  pickup_bonus?: boolean
  pickup_bonus_data?: BonusDataPayload[]
}

export interface BonusDataPayload {
  min_stt: number
  bonus: number
}

export interface BonusPercentagePayload {
  adjustment: number
  bonus_max_limit: number
  percentage: number
}

export interface SettingPayload {
  prefix: string[]
  setting: TierPayload[]
}

export interface TierPayload {
  fee?: number
  min_weight?: number
  multiply_type?: string
  parking_fee?: number
}

interface PayrollState {
  isLoading: boolean
  isLoadingHistoryManualAdjustBasicFee: boolean
  isLoadingDownloadTemplate: boolean
  isLoadingGetUploadBulkData: boolean
  isLoadingSubmitBulk: boolean
  statusUpload: EnumStatusUpload
  isLoadingDetail: boolean
  isChanged: boolean
  dataPayrollList: Payroll[]
  dataPayroll: Payroll
  dataPeriodRule: PeriodRule
  dataCourierAttendance: CourierAttendance[]
  dataCourierAttendanceDetail: CourierAttendanceDetails
  dataCourierRequestAttendanceDetail: CourierAttendanceRequestDetail
  dataManualAdjustBasicFee: ManualAdjustBasicFee[]
  dataHistoryManualAdjustBasicFee: ManualAdjustBasicFeeHistory[]
  dataHistoryManualAdjustBasicFeeDetail: ManualAdjustBasicFeeHistoryDetailData
  dataUploadBulky: ManualAdjustBasicFeeHistoryDetailData
  paginationData: Pagination
  historyManualAdjustBasicFeePaginationData: Pagination
  isAddPayrollSuccess: boolean
  isEditPeriodWithdrawSuccess: boolean
  isEditPeriodBasicFeeSuccess: boolean
  isEditPeriodBonusSuccess: boolean
  isSubmitCourierAttendanceApprovalSuccess: boolean
  isApproveAdjustBasicFeeSuccess: boolean
  isRejectAdjustBasicFeeSuccess: boolean
  errUploadBulky: string
  errSubmitBulky: string
  summaryAttendance: number
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'payroll',
})
class PayrollController extends VuexModule implements PayrollState {
  private presenter: PayrollPresenter = container.resolve(PayrollPresenter)
  public isLoading = false
  public isLoadingHistoryManualAdjustBasicFee = false
  public isLoadingDownloadTemplate = false
  public isLoadingGetUploadBulkData = false
  public isLoadingSubmitBulk = false
  public isLoadingDetail = false
  public isChanged = false
  public statusUpload = EnumStatusUpload.START
  public dataPayrollList = [new Payroll()]
  public dataPayroll = new Payroll()
  public dataPeriodRule = new PeriodRule()
  public dataCourierAttendance: CourierAttendance[] = []
  public dataCourierAttendanceDetail: CourierAttendanceDetails = new CourierAttendanceDetails()
  public dataCourierRequestAttendanceDetail: CourierAttendanceRequestDetail = new CourierAttendanceRequestDetail()
  public dataManualAdjustBasicFee: ManualAdjustBasicFee[] = []
  public dataHistoryManualAdjustBasicFee: ManualAdjustBasicFeeHistory[] = []
  public dataHistoryManualAdjustBasicFeeDetail: ManualAdjustBasicFeeHistoryDetailData = new ManualAdjustBasicFeeHistoryDetailData()
  public dataUploadBulky: ManualAdjustBasicFeeHistoryDetailData = new ManualAdjustBasicFeeHistoryDetailData()
  public paginationData = new Pagination()
  public historyManualAdjustBasicFeePaginationData = new Pagination()
  public isAddPayrollSuccess = false
  public isEditPeriodWithdrawSuccess = false
  public isEditPeriodBasicFeeSuccess = false
  public isEditPeriodBonusSuccess = false
  public isSubmitCourierAttendanceApprovalSuccess = false
  public isApproveAdjustBasicFeeSuccess = false
  public isRejectAdjustBasicFeeSuccess = false
  public errSubmitBulky = ''
  public errUploadBulky = ''
  public summaryAttendance = 0;

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res.data) this.setDataPayrollList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Period List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetail(params: { id: string; page: number }) {
    this.setLoadingDetail(true)

    this.presenter
      .get(params.id)
      .then(res => {
        if (res) {
          const payload = {
            page: params.page,
          }

          this.presenter.getAll(
            Utils.toInstance(new Map(), JSON.stringify(payload), 'snake_case')
          ).then((list) => {
            const status = list.data?.find((l) => {
              return l.id == res.id
            })?.status
            this.setDataPeriodRule({
              ...res,
              status
            })
          })
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Period Rule Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
  }

  @Action({ rawError: true })
  public create(payload: PayrollPeriodPayload) {
    this.setLoading(true)
    this.presenter
      .create(new CreatePayrollApiRequest(payload.startDate))
      .then(res => {
        this.setIsAddPayrollSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Payroll Period Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsAddPayrollSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePeriodWithdraw(payload: {
    id: string
    rules: FundsWithdrawalPayload
  }) {
    this.setLoading(true)
    this.presenter
      .update(payload.id, new UpdatePeriodWithdrawApiRequest(payload.rules))
      .then(res => {
        this.setIsEditPeriodWithdrawSuccess(res)
      })
      .catch(error => {
        this.setIsEditPeriodWithdrawSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePeriodBasicFee(payload: { id: string; rules: BasicFeePayload }) {
    this.setLoading(true)
    this.presenter
      .update(payload.id, new UpdatePeriodBasicFeeApiRequest(payload.rules))
      .then(res => {
        this.setIsEditPeriodBasicFeeSuccess(res)
      })
      .catch(error => {
        this.setIsEditPeriodBasicFeeSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePeriodBonusFee(payload: { id: string; rules: BonusFeePayload }) {
    this.setLoading(true)
    this.presenter
      .update(payload.id, new UpdatePeriodBonusFeeApiRequest(payload.rules))
      .then(res => {
        this.setIsPeriodBonusSuccess(res)
      })
      .catch(error => {
        this.setIsPeriodBonusSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public fetchAllCourierAttendance(
    params: Record<string, string | number | Date>
  ): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify({
        ...params,
        date: Utils.formatDateWithIDLocale((<Date>params.date).toISOString(), 'YYYY-MM-DD')}
      ),
      'snake_case'
    )

    const formattedMonth = Utils.formatDateWithIDLocale(
      (<Date>params.date).toISOString(),
      'YYYY-MM'
    )

    this.presenter
      .getAllCourierAttendance(formattedParams, formattedMonth)
      .then(res => {
        this.setCourierAttendanceData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Attendance Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public fetchCourierAttendanceDetail(
    payload: {
      params: Record<string, string | number | Date>,
      date: Date,
      courierId: number
    }
  ): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    const formattedMonth = Utils.formatDateWithIDLocale(
      (<Date>payload.date).toISOString(),
      'YYYY-MM'
    )

    this.presenter
      .getCourierAttendanceDetail(formattedParams, formattedMonth, payload.courierId)
      .then(res => {
        this.setCourierAttendanceDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Attendance Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public downloadBasicFeeUploadBulkyTemplate(): void {
    this.setLoadingDownloadTemplate(true)

    this.presenter
      .downloadBasicFeeUploadBulkyTemplate()
      .then((res) => {
        window.open(res)
      })
      .catch((error) => {
        Vue.notify({
          title: 'Download Basic Fee Upload Bulky Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingDownloadTemplate(false)
      })
  }

  @Action({ rawError: true })
  public getAllManualAdjustBasicFee(
    params: Record<string, string | number>
  ): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllManualAdjustBasicFee(formattedParams)
      .then(res => {
        this.setDataManualAdjustBasicFee(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manual Adjust Basic Fee List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public fetchCourierRequestAttendanceDetail(
    payload: {
      date: string,
      courierId: number
    }
  ): void {
    this.setLoading(true)

    this.presenter
      .getCourierRequestAttendance(payload.date, payload.courierId)
      .then(res => {
        this.setCourierRequestAttendanceDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Request Attendance Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAllHistoryManualAdjustBasicFee(
    params: Record<string, string | number>
  ): void {
    this.setLoadingHistoryManualAdjustBasicFee(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllManualAdjustBasicFeeHistory(formattedParams)
      .then(res => {
        this.setDataHistoryManualAdjustBasicFee(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch History Manual Adjust Basic Fee List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingHistoryManualAdjustBasicFee(false)
      })
  }

  @Action({ rawError: true })
  public submitCourierAttendancenApproval(
    payload: SubmitCourierAttendanceApprovalRequest
  ): void {
    this.setLoading(true)

    this.presenter
      .submitCourierAttendanceApproval(payload)
      .then(res => {
        this.setIsSubmitCourierAttendanceApprovalSuccess(res)
        Vue.notify({
          title: 'Submit Courier Attendance Approval Success',
          text: 'Success Update Courier Attendance Approval',
          type: 'success',
          duration: 3000
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Submit Courier Attendance Approval Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getHistoryManualAdjustBasicFee(
    params: Record<string, string | number>
  ): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getManualAdjustBasicFeeHistory(formattedParams)
      .then(res => {
        this.setDataHistoryManualAdjustBasicFeeDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch History Manual Adjust Basic Fee Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public uploadBulkyManualAdjustBasicFee(payload: { file: File }) {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.presenter
      .uploadBulkyManualAdjustBasicFee(
        new UploadBulkyManualAdjustBaicFeeRequest(payload.file)
      )
      .then(res => {
        this.setDataHistoryManualAdjustBasicFeeDetail(res)
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setErrUploadBulky(EventBusConstants.UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE)
      })
      .catch(error => {
        this.setErrUploadBulky(error.error.message.en)
        this.setStatusUpload(EnumStatusUpload.FAILED)
        if (!this.errUploadBulky.includes('is already uploaded')) {
          Vue.notify({
            title: 'Upload Bulky Failed',
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
  }

  @Action({ rawError: true })
  public getUploadBulkyManualAdjustBasicFeeList(
    params: Record<string, string | number>
  ): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getUploadBulkyManualAdjustBasicFeeList(formattedParams)
      .then(res => {
        this.setDataHistoryManualAdjustBasicFeeDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Upload Bulky List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public submitUploadBulkyManualAdjustBasicFee() {
    this.setLoadingSubmitBulk(true)

    this.presenter
      .submitUploadBulkyManualAdjustBasicFee()
      .then(res => {
        this.setErrSubmitBulky(EventBusConstants.SUBMIT_BULKY_MANUAL_ADJUSTMENT_BALANCE)
      })
      .catch(error => {
        this.setErrSubmitBulky(error.error.message.en)
        Vue.notify({
          title: 'Submit Bulk Failed',
          text:
            [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingSubmitBulk(false)
      })
  }

  @Action({ rawError: true })
  public submitApprovalManualAdjustBasicFee(
    payload: SubmitApprovalManualAdjustBasicFeeRequest
  ) {
    this.setLoading(true)

    this.presenter
      .submitApprovalManualAdjustBasicFee(payload)
      .then(() => {
        if (payload.status === 'APPROVED') {
          this.setIsApproveAdjustBasicFeeSuccess(true)
        } else this.setIsRejectAdjustBasicFeeSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Submit Approval Manual Adjust Basic Fee Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  public setIsChanged(bool: boolean) {
    this.isChanged = bool
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingHistoryManualAdjustBasicFee(bool: boolean): void {
    this.isLoadingHistoryManualAdjustBasicFee = bool
  }

  @Mutation
  public setLoadingSubmitBulk(bool: boolean) {
    this.isLoadingSubmitBulk = bool
  }

  @Mutation
  public setLoadingDetail(bool: boolean) {
    this.isLoadingDetail = bool
  }

  @Mutation
  private setDataPayrollList(list: Payrolls) {
    this.dataPayrollList = <Payroll[]>list.data
    this.paginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataPeriodRule(list: PeriodRule) {
    this.dataPeriodRule = list
  }

  @Mutation
  public setIsAddPayrollSuccess(bool: boolean) {
    this.isAddPayrollSuccess = bool
  }

  @Mutation
  public setIsEditPeriodWithdrawSuccess(bool: boolean) {
    this.isEditPeriodWithdrawSuccess = bool
  }

  @Mutation
  public setIsEditPeriodBasicFeeSuccess(bool: boolean) {
    this.isEditPeriodBasicFeeSuccess = bool
  }

  @Mutation
  public setIsPeriodBonusSuccess(bool: boolean) {
    this.isEditPeriodBonusSuccess = bool
  }

  @Mutation
  private setCourierAttendanceData(data: CourierAttendences): void {
    this.paginationData = <Pagination>data.pagination
    this.dataCourierAttendance = <CourierAttendance[]>data.data
    this.summaryAttendance = data.summary || 0
  }

  @Mutation
  private setCourierAttendanceDetailData(data: CourierAttendanceDetails): void {
    this.dataCourierAttendanceDetail = data
  }

  @Mutation
  private setCourierRequestAttendanceDetailData(data: CourierAttendanceRequestDetail): void {
    this.dataCourierRequestAttendanceDetail = data
  }

  @Mutation
  public setIsSubmitCourierAttendanceApprovalSuccess(bool: boolean): void {
    this.isSubmitCourierAttendanceApprovalSuccess = bool
  }

  @Mutation
  private setDataManualAdjustBasicFee(data: ManualAdjustBasicFees): void {
    this.dataManualAdjustBasicFee = <ManualAdjustBasicFee[]>data.data
    this.paginationData = <Pagination>data.pagination
  }

  @Mutation
  private setDataHistoryManualAdjustBasicFee(data: ManualAdjustBasicFeeHistories): void {
    this.dataHistoryManualAdjustBasicFee = <ManualAdjustBasicFeeHistory[]>data.data
    this.historyManualAdjustBasicFeePaginationData = <Pagination>data.pagination
  }

  @Mutation
  private setLoadingDownloadTemplate(bool: boolean): void {
    this.isLoadingDownloadTemplate = bool
  }

  @Mutation
  private setDataHistoryManualAdjustBasicFeeDetail(data: ManualAdjustBasicFeeHistoryDetail): void {
    this.dataHistoryManualAdjustBasicFeeDetail = <ManualAdjustBasicFeeHistoryDetailData>data.data
    this.paginationData = <Pagination>data.pagination
  }

  @Mutation
  public setStatusUpload(status: EnumStatusUpload) {
    this.statusUpload = status
  }

  @Mutation
  public setErrUploadBulky(err: string) {
    this.errUploadBulky = err
  }

  @Mutation
  public setErrSubmitBulky(err: string) {
    this.errSubmitBulky = err
  }

  @Mutation
  public setPaginationData(data: Pagination): void {
    this.paginationData = data
  }

  @Mutation
  public setIsApproveAdjustBasicFeeSuccess(bool: boolean): void {
    this.isApproveAdjustBasicFeeSuccess = bool
  }

  @Mutation
  public setIsRejectAdjustBasicFeeSuccess(bool: boolean): void {
    this.isRejectAdjustBasicFeeSuccess = bool
  }
}

export default getModule(PayrollController)
