

























































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import ChevronLeftIcon from '@/app/ui/assets/chevron-left.vue'
import ChevronRightIcon from '@/app/ui/assets/chevron-right.vue'
import CalendarIcon from '@/app/ui/assets/ics_f_calendar.vue'
import CheckIcon from '@/app/ui/assets/ics_o_check.vue'
import { Utils } from '@/app/infrastructures/misc'
import MonthPicker from './MonthPicker/index.vue'

export interface IDateRangeValue {
  start?: null | Date
  end?: null | Date
}

export interface IPresetRangeValue {
  key?: string
  label?: string
  value?: number
}

@Component({
  components: {
    Button,
    MonthPicker,
    ChevronLeftIcon,
    ChevronRightIcon,
    CalendarIcon,
    CheckIcon,
  },
})
export default class DateTimePickerV2 extends Vue {
  @Prop({ default: 'single' }) private type!:
    | 'single'
    | 'range'
    | 'range-1'
    | 'range-1-button'
  @Prop({ default: null }) private maxDate!: Date
  @Prop({ default: null }) private minDate!: Date
  @Prop({ default: undefined }) private value!:
    | Date
    | IDateRangeValue
    | undefined
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: 'Pilih Tanggal' }) private placeholder!: string
  @Prop({ default: false }) private usePresetRange!: boolean
  @Prop({ default: () => [] }) private presetRangeValue!: IPresetRangeValue[]
  @Prop({ default: 'default' }) private inputTemplate!: string
  @Prop({ default: '' }) private formatValue!: string
  @Prop({ default: false}) private alwaysOpen!: boolean
  @Prop({ default: false}) private onlyResetButton!: boolean

  isCalendarOpen = false
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  months = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  dateInit = new Date()
  firstDayIndex = 0
  lastDayIndex = 0
  lastDay = 0
  nextDay = 0
  currentMonth = 0
  selectedDate: null | Date = null

  rangeDateInit = new Date()
  rangeFirstDayIndex = 0
  rangeLastDayIndex = 0
  rangeLastDay = 0
  rangeNextDay = 0
  rangeCurrentMonth = 0
  rangeSelectedDate: null | Date = null

  range: IDateRangeValue = {
    end: null,
    start: null,
  }

  presets: IPresetRangeValue[] = [
    { key: 'chooseDate', label: 'Tentukan Tanggal', value: 0 },
  ]
  selectedPreset: IPresetRangeValue = {}

  created(): void {
    this.dateInit.setDate(1)
    this.dateInit.setMonth(this.dateInit.getMonth())
    this.currentMonth = this.dateInit.getMonth()
    this.setDate()

    this.rangeDateInit.setDate(1)
    this.rangeDateInit.setMonth(this.dateInit.getMonth() + 1)
    this.rangeCurrentMonth = this.rangeDateInit.getMonth()
    this.setDateRange()

    this.setSelectedDate()
    this.setPresets()

    // handle close calendar if more than one calendar open together in one page
    window.addEventListener('click', (e: Event) => {
      if (e.target instanceof HTMLElement && !this.$el.contains(e.target)) {
        this.isCalendarOpen = false
      }
    })
  }

  get customInput(): boolean {
    return !!this.$slots['custom-input']
  }

  get formatedDateValue(): string {
    if (this.type === 'single') {
      return `${new Date(<Date>this.value).getDate()} ${
        this.months[new Date(<Date>this.value).getMonth()]
      } ${new Date(<Date>this.value).getFullYear()}`
    } else {
      return `${new Date(<Date>this.range.start).getDate()} ${
        this.months[new Date(<Date>this.range.start).getMonth()]
      } ${new Date(<Date>this.range.start).getFullYear()} - ${new Date(
        <Date>this.range.end
      ).getDate()} ${
        this.months[new Date(<Date>this.range.end).getMonth()]
      } ${new Date(<Date>this.range.end).getFullYear()}`
    }
  }

  get makeFormatDateValue(): string {
    if (this.formatValue !== '') {
      return Utils.formatDate(
        (<Date>this.value).toDateString(),
        this.formatValue
      )
    }
    return this.formatedDateValue
  }

  get isEmptyValue(): boolean {
    if (this.type === 'single') {
      return Boolean(this.value)
    } else {
      return Boolean(this.range.start && this.range.end)
    }
  }

  private setDate(): void {
    this.dateInit.setDate(1)
    this.lastDay = new Date(
      this.dateInit.getFullYear(),
      this.dateInit.getMonth() + 1,
      0
    ).getDate()
    this.firstDayIndex = this.dateInit.getDay()
    this.lastDayIndex = new Date(
      this.dateInit.getFullYear(),
      this.dateInit.getMonth() + 1,
      0
    ).getDay()
    this.nextDay = 7 - this.lastDayIndex - 1
  }

  private setDateRange(): void {
    this.rangeDateInit.setDate(1)
    this.rangeLastDay = new Date(
      this.rangeDateInit.getFullYear(),
      this.rangeDateInit.getMonth() + 1,
      0
    ).getDate()
    this.rangeFirstDayIndex = this.rangeDateInit.getDay()
    this.rangeLastDayIndex = new Date(
      this.rangeDateInit.getFullYear(),
      this.rangeDateInit.getMonth() + 1,
      0
    ).getDay()
    this.rangeNextDay = 7 - this.rangeLastDayIndex - 1
  }

  private nextDate(): void {
    this.currentMonth++
    let tempDate = new Date(this.dateInit.toDateString())
    tempDate = this.calculateNextYearDate(tempDate, this.currentMonth)
    this.dateInit = tempDate
    this.currentMonth = this.dateInit.getMonth()
    this.setDate()

    this.rangeCurrentMonth++
    let rangeTempDate = new Date(this.rangeDateInit.toDateString())
    this.rangeDateInit = this.calculateNextYearDate(
      rangeTempDate,
      this.rangeCurrentMonth
    )
    this.rangeCurrentMonth = this.rangeDateInit.getMonth()
    this.setDateRange()
  }

  private calculateNextYearDate(tempDate: Date, currentMonth: number): Date {
    if (currentMonth === 12) {
      tempDate.setFullYear(tempDate.getFullYear() + 1, 0, 1)
      currentMonth = 0
    } else {
      tempDate.setDate(1)
      tempDate.setMonth(currentMonth)
    }

    return tempDate
  }

  private prevDate(): void {
    this.currentMonth--
    let tempDate = new Date(this.dateInit.toDateString())
    tempDate = this.calculatePrevYearDate(tempDate, this.currentMonth)
    this.dateInit = tempDate
    this.currentMonth = this.dateInit.getMonth()
    this.setDate()

    this.rangeCurrentMonth--
    let rangeTempDate = new Date(this.rangeDateInit.toDateString())
    rangeTempDate = this.calculatePrevYearDate(
      rangeTempDate,
      this.rangeCurrentMonth
    )
    this.rangeDateInit = rangeTempDate
    this.rangeCurrentMonth = this.rangeDateInit.getMonth()
    this.setDateRange()
  }

  private calculatePrevYearDate(tempDate: Date, currentMonth: number): Date {
    if (currentMonth === -1) {
      tempDate.setFullYear(tempDate.getFullYear() - 1, 11, 1)
      currentMonth = 11
    } else {
      tempDate.setDate(1)
      tempDate.setMonth(currentMonth)
    }

    return tempDate
  }

  private selectDate(date: number, month: Date, styleClass: string): void {
    if (styleClass !== 'disabled') {
      if (this.type === 'range' || this.type.includes('range-1')) {
        this._setDateRange(date, month)
      } else {
        const value = new Date(month.setDate(date))
        value.setHours(0, 0, 0, 0)
        this.selectedDate = value
      }
    }
  }

  private _setDateRange(date: number, month: Date): void {
    const tempDate = new Date(month.setDate(date))
    tempDate.setHours(0, 0, 0, 0)
    if (this.range.start && this.range.end) {
      this.range.start = tempDate
      this.range.end = null
      return
    }
    if (!this.range.start) {
      this.range.start = tempDate
    } else if (tempDate.getTime() < this.range.start.getTime()) {
      this.range.start = tempDate
    } else {
      this.range.end = tempDate
      if (this.onlyResetButton) {
        this.$emit('input', this.range)
      }
    }
  }

  private decideDateStyle(
    date: number,
    month: Date,
    lastDay?: number
  ): undefined | string {
    month.setDate(date)
    month.setHours(0, 0, 0, 0)
    if (this._decideMaxDateStyle(date, month)) {
      return this._decideMaxDateStyle(date, month)
    }
    if (this._decideMinDateStyle(date, month)) {
      return this._decideMinDateStyle(date, month)
    }
    if (this._decideRangeStyle(date, month, lastDay)) {
      return this._decideRangeStyle(date, month, lastDay)
    }
    if (this._decideSelectedStyle(month)) {
      return this._decideSelectedStyle(month)
    }
    if (this._decideTodayDateStyle(date, month)) {
      return this._decideTodayDateStyle(date, month)
    }
  }

  private _decideRangeStyle(
    date: number,
    month: Date,
    lastDay?: number
  ): string | undefined {
    const tempDate = new Date(month.setDate(date))
    tempDate.setHours(0, 0, 0, 0)
    if (this.range.start && this.range.end) {
      if (this.range.start.getTime() === tempDate.getTime()) {
        return 'selected start-range'
      }
      if (this.range.end.getTime() === tempDate.getTime()) {
        return 'selected end-range'
      }
      if (
        tempDate.getTime() === this.range.end.getTime() &&
        this.range.start.getTime() === tempDate.getTime()
      ) {
        return 'selected'
      }
      if (
        tempDate.getTime() < this.range.end?.getTime() &&
        tempDate.getTime() > this.range.start.getTime()
      ) {
        if (this._decideTodayDateStyle(date, month)) {
          return 'range today'
        }
        if (tempDate.getDate() === lastDay && tempDate.getDay() === 0) {
          return 'full-range'
        }
        if (tempDate.getDate() === 1 && tempDate.getDay() === 6) {
          return 'full-range'
        }
        if (tempDate.getDate() === 1 || tempDate.getDay() === 0) {
          return 'start-range'
        }
        if (tempDate.getDay() === 6) {
          return 'end-range'
        }
        if (tempDate.getDate() === lastDay) {
          return 'end-range'
        }
        return 'range'
      }
    }
  }

  private _decideSelectedStyle(month: Date): string | undefined {
    this.selectedDate?.setHours(0, 0, 0, 0)
    if (
      (this.selectedDate &&
        month.toISOString() === this.selectedDate.toISOString()) ||
      month.toISOString() === this.range.start?.toISOString() ||
      month.toISOString() === this.range.end?.toISOString()
    ) {
      return 'selected'
    }
  }

  private _decideTodayDateStyle(date: number, month: Date): string | undefined {
    if (
      date === new Date().getDate() &&
      month.getMonth() === new Date().getMonth() &&
      month.getFullYear() === new Date().getFullYear()
    ) {
      if (this.type.includes('range-1')) {
        return 'today-1'
      }
      return 'today'
    }
  }

  private _decideMaxDateStyle(date: number, month: Date): string | undefined {
    const tempMonth = month
    tempMonth.setDate(date)
    if (this.maxDate) {
      const tempMaxDate = this.maxDate
      tempMaxDate.setHours(0, 0, 0, 0)
      if (tempMonth > tempMaxDate) {
        return 'disabled'
      }
    }
  }

  private _decideMinDateStyle(date: number, month: Date): string | undefined {
    const tempMonth = month
    tempMonth.setDate(date)
    if (this.minDate) {
      const tempMinDate = this.minDate
      tempMinDate.setHours(0, 0, 0, 0)
      if (tempMonth < tempMinDate) {
        return 'disabled'
      }
    }
  }

  get decideDisableSubmitButton(): boolean {
    if (this.type === 'range' || this.type.includes('range-1')) {
      return Boolean(!this.range.start && !this.range.end)
    }
    return Boolean(!this.selectedDate)
  }

  public onSelectMonth(value: Date): void {
    this.currentMonth = value.getMonth()
    this.dateInit.setFullYear(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()
    )
    this.setDate()
    this.setDateRange()

    if (this.type === 'range') {
      this.rangeCurrentMonth = value.getMonth() + 1
      let rangeTempDate = new Date(this.rangeDateInit.toDateString())
      rangeTempDate.setFullYear(value.getFullYear())
      this.rangeDateInit = this.calculateNextYearDate(
        rangeTempDate,
        this.rangeCurrentMonth
      )
      this.rangeCurrentMonth = this.rangeDateInit.getMonth()
      this.setDateRange()
    }
  }

  private setSelectedDate(): void {
    if (this.type === 'single') {
      if (this.value) {
        this.dateInit.setFullYear((<Date>this.value).getFullYear())

        this.currentMonth = <number>(<Date>this.value).getMonth()
        this.selectedDate = <Date>this.value
        this.selectedDate.setHours(0, 0, 0, 0)
        this.dateInit.setFullYear((<Date>this.value).getFullYear())
        this.dateInit.setMonth((<Date>this.value).getMonth())
      } else {
        this.selectedDate = null
      }
    } else {
      this.range = { ...(<IDateRangeValue>this.value) }
      this.range.start?.setHours(0, 0, 0, 0)
      this.range.end?.setHours(0, 0, 0, 0)
    }
  }

  private setPresets(): void {
    if (this.presetRangeValue.length) {
      this.presets = [...this.presets, ...this.presetRangeValue]
    } else {
      this.presets.push(
        { key: 'last7Days', label: '7 Hari Terakhir', value: 7 },
        { key: 'last14Days', label: '14 Hari Terakhir', value: 14 },
        { key: 'last30Days', label: '30 Hari Terakhir', value: 30 }
      )
    }
  }

  private openCalendar(): void {
    if (!this.disabled) {
      this.isCalendarOpen = true
      this.setSelectedDate()
      this.selectedPreset = this.presets[0]
    }
  }

  private async closeCalendar(): Promise<void> {
    if (this.type === 'range-1' && this.range.end) {
      await this.setDateValue()
    }
    this.isCalendarOpen = false
    this.setSelectedDate()
    this.selectedPreset = this.presets[0]
  }

  private async onSubmit(): Promise<void> {
    await this.setDateValue()
    this.closeCalendar()
  }

  private onReset(): void {
    this.selectedDate = null
    this.range.start = null
    this.range.end = null
    this.$emit('reset', undefined)

    if (this.value) {
      this.setDateValue()
    }
  }

  private async setDateValue(): Promise<void> {
    if (this.type === 'range' || this.type.includes('range-1')) {
      let valueModel: IDateRangeValue = {
        ...this.range,
      }
      if (this.range.end === null) {
        valueModel = {
          start: this.range.start,
          end: this.range.start,
        }
      }
      this.$emit('input', { ...valueModel })
      this.$emit('update:modelValue', { ...valueModel })
      await this.$nextTick()
    } else {
      this.$emit('input', this.selectedDate)
      this.$emit('update:modelValue', this.selectedDate)
    }
  }

  private onPreset(preset: IPresetRangeValue): void {
    const tempDate = new Date()
    tempDate.setHours(0, 0, 0, 0)
    this.range.end = new Date()
    this.range.end.setHours(0, 0, 0, 0)
    tempDate.setTime(
      tempDate.getTime() - <number>preset.value * (1000 * 60 * 60 * 24)
    )
    this.range.start = tempDate
    this.selectedPreset = preset

    if (this.onlyResetButton) {
      this.$emit('input', this.range)
    }
  }
}
