import {
  BasicFeePayload,
  BonusDataPayload,
  BonusPercentagePayload,
} from '@/app/ui/controllers/PayrollController'
import {
  CreatePayrollRequestInterface,
  SubmitCourierAttendanceApprovalRequestInterface,
  SubmitApprovalManualAdjustBasicFeeRequestInterface,
  UpdatePayrollRuleRequestInterface,
  UploadBulkyManualAdjustBaicFeeRequestInterface,
} from '@/data/payload/contracts/PayrollRequest'

export class CreatePayrollApiRequest implements CreatePayrollRequestInterface {
  public startDate?: string

  constructor(startDate?: string) {
    this.startDate = startDate
  }

  public toPayload(): string {
    const data = {
      start_date: this.startDate,
    }
    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdatePeriodBasicFeeApiRequest
  implements UpdatePayrollRuleRequestInterface {
  public rules?: BasicFeePayload

  constructor(rules?: BasicFeePayload) {
    this.rules = rules
  }

  public toPayload(): string {
    const data = {
      rules: this.rules,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdatePeriodWithdrawApiRequest
  implements UpdatePayrollRuleRequestInterface {
  public rules?: {
    min_payout_amount?: number
    min_saldo?: number
    payout_fee?: number
  }

  constructor(rules?: {
    min_payout_amount?: number
    min_saldo?: number
    payout_fee?: number
  }) {
    this.rules = rules
  }

  public toPayload(): string {
    const data = {
      rules: this.rules,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdatePeriodBonusFeeApiRequest
  implements UpdatePayrollRuleRequestInterface {
  public rules?: {
    bonus_recipient?: string[]
    captain_bonus?: string[]
    captain_bonus_fee?: number
    captain_bonus_options?: string[]
    insurance?: boolean
    performance_bonus?: boolean
    performance_bonus_data?: BonusDataPayload[]
    percentage_bonus?: boolean
    percentage_bonus_data?: BonusPercentagePayload[]
    pickup_bonus?: boolean
    pickup_bonus_data?: BonusDataPayload[]
  }

  constructor(rules?: {
    bonus_recipient?: string[]
    captain_bonus?: string[]
    captain_bonus_fee?: number
    captain_bonus_options?: string[]
    insurance?: boolean
    performance_bonus?: boolean
    performance_bonus_data?: BonusDataPayload[]
    percentage_bonus?: boolean
    percentage_bonus_data?: BonusPercentagePayload[]
    pickup_bonus?: boolean
    pickup_bonus_data?: BonusDataPayload[]
  }) {
    this.rules = rules
  }

  public toPayload(): string {
    const data = {
      rules: this.rules,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class SubmitCourierAttendanceApprovalRequest
  implements SubmitCourierAttendanceApprovalRequestInterface {
  public attendanceId?: number
  public courierId?: number
  public date?: string
  public approval?: string
  public proofs?: string[]
  public note?: string

  constructor(
    attendanceId?: number,
    courierId?: number,
    date?: string,
    approval?: string,
    proofs?: string[],
    note?: string
  ) {
    this.attendanceId = attendanceId
    this.courierId = courierId
    this.date = date
    this.approval = approval
    this.proofs = proofs
    this.note = note
  }

  toPayload(): string {
    const payload = {
      attendance_id: this.attendanceId,
      courier_id: this.courierId,
      date: this.date,
      approval: this.approval,
      proofs: this.proofs,
      note: this.note,
    }

    return JSON.stringify(payload)
  }
}

export class UploadBulkyManualAdjustBaicFeeRequest
  implements UploadBulkyManualAdjustBaicFeeRequestInterface {
  public file?: File

  constructor(file?: File) {
    this.file = file
  }

  toPayload(): FormData {
    const formData = new FormData()

    formData.append('file', <File>this.file)

    return formData
  }
}

export class SubmitApprovalManualAdjustBasicFeeRequest
  implements SubmitApprovalManualAdjustBasicFeeRequestInterface {
  public id?: number
  public status?: string
  public reason?: string

  constructor(id?: number, status?: string, reason?: string) {
    this.id = id
    this.status = status
    this.reason = reason
  }

  toPayload(): string {
    const payload = {
      id: this.id,
      status: this.status,
      reason: this.reason,
    }

    return JSON.stringify(payload)
  }
}
